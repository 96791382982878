// Changes Navigation Background Color On scroll of > 50px
const initialSrc = 'dist/img/logo-white.png';
const scrollSrc = 'dist/img/logo.png';

$(document).ready(() => {
  $(window).on('scroll', () => {
    if ($(window).width() > 992) {
      if ($(window).scrollTop() > 50) {
        $('.transparent-header').addClass('header-active');
        $('.navbar-dark .navbar-nav .nav-link').addClass('text-black');
        $('.login-btn').removeClass('border-white');
        $('.login-btn').addClass('border-black');
        $('.login-btn a').addClass('text-black');
        $('#main-nav').addClass('scroll-pad');
        $('.desktop-logo').attr('src', scrollSrc);
      } else {
        // remove the background property so it comes transparent again (defined in your css)
        $('.transparent-header').removeClass('header-active');
        $('.navbar-dark .navbar-nav .nav-link').removeClass('text-black');
        $('.login-btn').removeClass('border-black');
        $('.login-btn').addClass('border-white');
        $('.login-btn a').removeClass('text-black');
        $('#main-nav').removeClass('scroll-pad');
        $('.desktop-logo').attr('src', initialSrc);
      }
    }
  });


  // Offset Fixed Navigation
  $('.nav-link, .mobile-links a, .dropdown-item, .btn-prime').click(e => {
    const linkTarget = $(e.currentTarget).attr('href');

    if (linkTarget === '#' || linkTarget.charAt(0) !== '#') {
      return;
    }
    e.preventDefault();

    let topOffset = $(linkTarget).offset().top;
    let navContainer = '.fixed-top';
    if ($('.mobile-nav').is(':visible')) {
      if ($('#burger').is(':checked')) {
        navContainer = '.mobile-menu input~nav';
        $('#burger').trigger('click');
      }
    }

    topOffset -= $(navContainer).height();

    $('html, body').animate({
      scrollTop: topOffset,
    }, 900);
  });

  $('.video .nav-link').click((e) => {
    e.preventDefault();
  });

    // Social Proof
    $('.events-slider').slick({
        arrows: false,
        infinite: true,
        dots: true,
        fade: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
      });
      

  // Social Proof

  $('.logo-slider').slick({
    infinite: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [{
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      }, {
        breakpoint: 680,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      }, {
        breakpoint: 576,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          centerMode: true,
        },
      }, {
        breakpoint: 520,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      }, {
        breakpoint: 420,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerMode: false,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object,
    ],
  });

  // Wheel Click Modal
  $('button.btn-prime, button').on('mousedown', (e) => {
    if (e.which === 2) {
      e.preventDefault();
      e.stopPropagation();
      $(e.currentTarget).trigger('click');
    }
  });
});
